<template>
  <div class="container-fluid award-detail " >
    <div ref="playerSizeHelper" class="container"></div>
    <template v-if="currentAward">

      <div class="row">
        <div class="col">
          <h4 class="text-center mt-3">{{ currentAward.title }}</h4>
        </div>
      </div>

      <div class="row award-video mt-3 mb-5">
        <div class="container " style="margin-bottom: -8px;">
          <div class="row">
            <div v-if="currentAward.video" class="playerContainer" :key="size_mutated">
              <vimeo-player 
                      ref="player" class="vimeo" 
                      :player-width="playerWidth" 
                      :player-height="playerHeight" 
                      :autoplay="playing"
                      :controls="playing"
                      :loop="false"
                      @ended="playbackEnded"
                      :video-url="currentAward.video" />
              <div v-if="!playing" class="playerControl align-items-center justify-content-center d-flex cursor-pointer" @click="playPauseVideo">
                <div class="playerControlBg" >
                  <img src="@/assets/img/play_icon.png" class="play-icon-3"/>
                </div>
              </div>
            </div>
            <img v-else :src="currentAward.horizontal_poster" :alt="currentAward.title" class="w-100">
          </div>
        </div>
      </div>

      <div class="container p-0">
        <div class="row mt-3 mb-5 justify-content-center">
          <div class="col-6">
            <img :src="currentAward.icon" :alt="currentAward.title" class="logo-prize"> <br>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
<!--            <p v-html="currentAward.short_description"></p>-->
            <p v-html="currentAward.description"/>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row no-gutters">
          <div class="col-12 ">
            <hr class="border-light-blue">
            <h5 class="text-center mb-3">{{$t('Películas Ganadoras o Nominadas')}}</h5>
            <MovieCarousel :movies="currentAward.related_movies.data" :title="$t('Películas Ganadoras o \<br\> Nominadas en ViendoMovies')" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>

      <div class="row">
        <div class="col">
          <h1 class="text-center">...</h1>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import MovieCarousel from "../components/modules/MovieCarousel";

export default {
  name: "Awards",
  components: {MovieCarousel},
  props: {
    slug : {
      required : false,
      type : undefined
    }
  },
  methods:{
    ...mapGetters(["award"]),
    fetchAward: function(){
      this.$store.dispatch("fetchAward",this.slug).then(data => {
        this.currentAward = data;
        this.calculatePlayerSize();
      });
    },
    calculatePlayerSize(){
      this.playerWidth = this.$refs.playerSizeHelper.clientWidth;
      this.playerHeight = this.playerWidth * 9/16;
      this.size_mutated = this.size_mutated +1;
    },
    playPauseVideo(){
      if(this.playing){
        this.pauseVideo();
      }else{
        this.playVideo();
      }
    },
    playVideo(){
      this.playing=true;
      this.$nextTick(()=>{
        setTimeout(()=>{
          this.$refs.player.play();
        }, 200);
      })
    },
    playbackEnded(){
      //this.pauseVideo();
    },
    pauseVideo(){
      this.playing=false;
      this.$refs.player.pause();
    }
  },
  data(){

    return{
      currentAward: null,
      playerWidth: 0,
      playerHeight: 0,
      playing: false,
      resizeEvent: null,
      size_mutated: 0
    }
  },
  mounted() {
    this.fetchAward();
    window.onresize = ()=>{
      clearTimeout(this.resizeEvent);
      this.resizeEvent = setTimeout(this.calculatePlayerSize, 100);
    };
  }
}
</script>

<style lang="scss">
.award-detail {
  .award-video {
    background-color : black;
  }

  .logo-prize{
    max-width: 100%;
    max-height: 100px;
    margin: auto;
    display: block;
  }
  .playerContainer{
    position: relative;
    .playerControl{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      .playerControlBg{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        img{
          top: 50%;
          position: relative;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>